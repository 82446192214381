<script setup lang="ts">
import { computed } from 'vue';
import { useQuantityValidation } from '~/composables/useQuantityValidation';
import type { Schemas } from '#shopware';

const { product, minPurchaseIsInvalid, maxPurchaseIsInvalid, purchaseStepsIsInvalid } = useQuantityValidation();
const { cartItems } = useCart();

const cartItem = computed(() => {
    return cartItems.value?.find((item: Schemas['LineItem']) => item.referencedId === product.value?.id);
});
</script>

<template>
    <div class="text-sm">
        <p
            class="flex justify-between gap-2 min-purchase-wrapper mb-2"
            :class="[minPurchaseIsInvalid ? 'text-brand-danger' : 'text-brand-success']"
        >
            <span>{{ $t('product.quantityValidationMinPurchaseText') }}:</span> <span>{{ product?.minPurchase }}</span>
        </p>

        <p
            class="flex justify-between gap-2 purchase-steps-wrapper mb-2"
            :class="[purchaseStepsIsInvalid ? 'text-brand-danger' : 'text-brand-success']"
        >
            <span>{{ $t('product.quantityValidationPurchaseStepsText') }}:</span>
            <span>{{ product?.purchaseSteps }}</span>
        </p>

        <p
            v-if="product?.packUnit"
            class="flex justify-between gap-2 mb-2"
        >
            <span>{{ $t('product.quantityValidationPackagingUnitText') }}:</span> <span>{{ product.packUnit }}</span>
        </p>

        <p
            v-if="cartItem"
            class="flex justify-between gap-2 mb-2"
        >
            <span>{{ $t('product.quantityValidationPAlreadyInCartText') }}:</span> <span>{{ cartItem?.quantity }}</span>
        </p>

        <p
            v-if="product?.maxPurchase"
            class="flex justify-between gap-2 mb-2"
            :class="[maxPurchaseIsInvalid ? 'text-brand-danger' : 'text-brand-success']"
        >
            <span>{{ $t('product.quantityValidationMaxPurchaseTextIsCloseout') }}:</span>
            <span>{{ product?.maxPurchase }}</span>
        </p>
    </div>
</template>
